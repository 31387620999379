import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Gallery from "react-photo-gallery";
import { Box, Heading, Grid, Button, GridItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Nav from "../Comnponents/Nav";
import { getUrl, list } from "aws-amplify/storage";

function Dispay() {
  // Create a reference under which you want to list
  const { state } = useLocation();
  const [img, setImg] = useState([]);
  const org = state.state["OrgID"];
  const index = state.state["Index"];

  async function geturl(ref) {
    const url = await getUrl({
      path: ref,
      options: {
        validateObjectExistence: true, // defaults to false
      },
    });

    setImg((oldArray) => [
      ...oldArray,
      {
        src: url.url.href,
        width: 3,
        height: 3,
      },
    ]);
  }

  async function start(org) {
    try {
      const response = await list({
        path: `public/${org}`,
      });

      response.items.forEach((itemRef) => {
        if (itemRef.size) {
          geturl(itemRef.path);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    start(org).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <Box w="100%" h="100vh" p={4}>
      <Nav
        color="black"
        link={[
          `/map/?lat=${state.state["Lat"]}&lon=${state.state["Lon"]}&org=${index}`,
          "Back to Map",
        ]}
      />
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <GridItem justifySelf={"center"}>
          <Heading as="h1" size="lg" color="black">
            {state.state["OrgName"]}
          </Heading>
        </GridItem>
      </Grid>

      <Box mt="15px">
        <Gallery photos={img} />
      </Box>
    </Box>
  );
}

export default Dispay;
